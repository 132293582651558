<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations[isUpdate ? 'updateProject' : 'newProject'] }}</h3>
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="tab" background-color="white" slider-color="blue">
            <v-tab key="project">{{ translations.details }}</v-tab>
            <v-tab key="parts">{{ translations.parts }} ({{ partsCount }})</v-tab>

            <v-tab-item key="details">
              <v-card-text>
                <!-- keyCode -->
                <v-col class="pa-0 mt-4">
                  <b class="ml-8">{{ translations.code }} *</b>
                  <smart-text-field v-model="selectedItem.keyCode" prepend-icon="mdi-roman-numeral-3"
                                    :readonly="isReadOnly" counter="20"
                                    :rules="[requiredRule]"
                                    :already-exists-function="checkKeyCode"
                                    @has-error="value => hasError.keyCode = value">
                  </smart-text-field>
                </v-col>

                <!-- label -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.label }} *</b>
                  <v-text-field v-model="selectedItem.label" prepend-icon="mdi-text"
                                :readonly="isReadOnly" :rules="[requiredRule]" counter="50"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- description -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.description }} *</b>
                  <v-textarea v-model="selectedItem.description" prepend-icon="mdi-text-long"
                              :readonly="isReadOnly" :rules="[requiredRule]"
                              class="mt-1 textarea-small" outlined dense>
                  </v-textarea>
                </v-col>

                <!-- startDate -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.startDate }} *</b>
                  <date-picker :dateProp="dateStarted"
                               @selected-date="value => setDate(value, 'dateStarted')"
                               :disable-picker="isReadOnly"
                               :classToAdd="['date-picker-component']">
                  </date-picker>
                </v-col>

                <!-- endDate -->
                <v-col class="pa-0 mt-8">
                  <b class="ml-8">{{ translations.endDate }}</b>
                  <date-picker :dateProp="dateFinished"
                               :startToDate="startToDate"
                               :minDateProp="dateStarted.simple"
                               @selected-date="value => setDate(value, 'dateFinished')"
                               :disable-picker="isReadOnly"
                               :clearable="!isReadOnly"
                               :classToAdd="['date-picker-component']">
                  </date-picker>
                </v-col>

                <v-divider class="mt-12 mb-10"></v-divider>

                <!-- clientCode -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.clientCode }}</b>
                  <v-text-field v-model="selectedItem.clientCode" prepend-icon="mdi-roman-numeral-4"
                                :readonly="isReadOnly"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- city -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.city }}</b>
                  <v-text-field v-model="selectedItem.city" prepend-icon="mdi-city-variant-outline"
                                :readonly="isReadOnly" counter="50"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- province -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.province }}</b>
                  <v-text-field v-model="selectedItem.province" prepend-icon="mdi-city-variant"
                                :readonly="isReadOnly" counter="20"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- latitude -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.latitude }}</b>
                  <v-text-field v-model="selectedItem.latitude" prepend-icon="mdi-map-marker-minus-outline"
                                :readonly="isReadOnly" :rules="[onlyDigitsRule]"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- longitude -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.longitude }}</b>
                  <v-text-field v-model="selectedItem.longitude" prepend-icon="mdi-map-marker-minus"
                                :readonly="isReadOnly" :rules="[onlyDigitsRule]"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <v-divider class="mt-7 mb-10"></v-divider>

                <!-- supervisor -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.supervisor }}</b>
                  <v-text-field v-model="selectedItem.supervisor" prepend-icon="mdi-account-hard-hat-outline"
                                :readonly="isReadOnly"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- allowance -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.allowance }}</b>
                  <v-text-field v-model="selectedItem.allowance" prepend-icon="mdi-account-cash-outline"
                                :readonly="isReadOnly" counter="20"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- status -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.status }}</b>
                  <v-text-field v-model="selectedItem.status" prepend-icon="mdi-tune"
                                :readonly="isReadOnly" counter="1"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <v-divider class="mt-6 mb-12"></v-divider>

                <!-- deliveryDate -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.deliveryDate }}</b>
                  <date-picker :dateProp="deliveryDate"
                               @selected-date="value => setDate(value, 'deliveryDate')"
                               :disable-picker="isReadOnly"
                               :clearable="!isReadOnly"
                               :classToAdd="['date-picker-component']">
                  </date-picker>
                </v-col>

                <!-- expirationDate -->
                <v-col class="pa-0 mt-8">
                  <b class="ml-8">{{ translations.expirationDate }}</b>
                  <date-picker :dateProp="expirationDate"
                               @selected-date="value => setDate(value, 'expirationDate')"
                               :disable-picker="isReadOnly"
                               :clearable="!isReadOnly"
                               :classToAdd="['date-picker-component']">
                  </date-picker>
                </v-col>

                <!-- effectiveDays -->
                <v-col class="pa-0 mt-8">
                  <b class="ml-8">{{ translations.effectiveDays }}</b>
                  <v-text-field v-model="selectedItem.effectiveDays" prepend-icon="mdi-calendar-today-outline"
                                :readonly="isReadOnly" :rules="[onlyDigitsRule]"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <v-divider class="mt-6 mb-12"></v-divider>

                <!-- productionCenterCode -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.productionCenterCode }}</b>
                  <v-text-field v-model="selectedItem.productionCenterCode" prepend-icon="mdi-roman-numeral-6"
                                :readonly="isReadOnly" counter="6"
                                :clearable="!isReadOnly"
                                class="mt-1" outlined dense>
                  </v-text-field>
                </v-col>

                <!-- productionCenterDescription -->
                <v-col class="pa-0 mt-1">
                  <b class="ml-8">{{ translations.productionCenterDescription }}</b>
                  <v-textarea v-model="selectedItem.productionCenterDescription" prepend-icon="mdi-text-long"
                              :readonly="isReadOnly"
                              class="mt-1 textarea-small" outlined dense>
                  </v-textarea>
                </v-col>
              </v-card-text>
            </v-tab-item>

            <v-tab-item key="parts">
              <!-- add part -->
              <div class="d-flex justify-end">
                <v-btn v-if="currentUserCanManageCRUD" @click="addNewPart"
                       color="main" small outlined class="white--text px-6">
                  {{ translations.addPart }}
                </v-btn>
              </div>

              <!-- parts -->
              <div v-for="(part, index) in selectedItem.parts" :key="index">
                <table style="width: 100%" class="partTable">
                  <caption></caption>
                  <tr>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <v-icon>mdi-roman-numeral-3</v-icon>
                      <b class="pt-2 pl-1">{{ translations.code }} *</b>
                    </td>
                    <td style="width: 60%">
                      <v-icon>mdi-text</v-icon>
                      <b class="pt-2 pl-1">{{ translations.description }} *</b>
                    </td>
                    <td style="width: 50px"></td>
                  </tr>
                  <tr>
                    <td>
                      <v-text-field v-model="part.keyCode"
                                    :readonly="isReadOnly" counter="10" :rules="[requiredRule, duplicateValueRule(selectedItem.parts, 'keyCode', index)]"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field v-model="part.description"
                                    :readonly="isReadOnly" :rules="[requiredRule]"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </td>
                    <td class="pb-5">
                      <v-btn v-if="currentUserCanManageCRUD" icon @click.stop="deleteConfirmation(part, index)" color="red">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </table>
                <v-divider class="my-2"></v-divider>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn v-if="currentUserCanManageCRUD"
                   @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'
import { Rules } from '@/utils/mixins/Rules'

import ProjectService from '@/service/project/ProjectService'

import SmartTextField from '@/components/SmartTextField'
import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'ProjectSaveEditDialog',

  components: {
    SmartTextField,
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,
      hasError: {
        keyCode: false
      },

      tab: 'project',

      selectedItem: {},
      startToDate: '',
      dateStarted: null,
      dateFinished: null,
      deliveryDate: null,
      expirationDate: null
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    ...mapState({
      currentUserCanManageCRUD: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage CRUD')
    }),

    disableBtn() {
      return !(
        (!this.hasError.keyCode && this.validRequired(this.selectedItem.keyCode, 20))
        && (this.validRequired(this.selectedItem.label, 50))
        && (this.validRequired(this.selectedItem.description, 100))
        && (!!this.dateStarted.simple)

        && (!this.selectedItem.city || this.selectedItem.city.length <= 50)
        && (!this.selectedItem.province || this.selectedItem.province.length <= 20)
        && (!this.selectedItem.latitude || this.onlyDigitsRegex().test(this.selectedItem.latitude))
        && (!this.selectedItem.longitude || this.onlyDigitsRegex().test(this.selectedItem.longitude))

        && (!this.selectedItem.allowance || this.selectedItem.allowance.length <= 20)
        && (!this.selectedItem.status || this.selectedItem.status.length <= 1)
        && (!this.selectedItem.effectiveDays || this.onlyDigitsRegex().test(this.selectedItem.effectiveDays))

        && (!this.selectedItem.productionCenterCode || this.selectedItem.productionCenterCode.length <= 6)

        && (this.selectedItem.parts
            ? this.selectedItem.parts.every(part => this.validRequired(part.keyCode, 10) && this.validRequired(part.description))
            && !this.selectedItem.parts.some((part, index) => this.checkDuplicateValue(this.selectedItem.parts, 'keyCode', index, part.keyCode))
            : true
        )
      )
    },

    isReadOnly() {
      return !this.currentUserCanManageCRUD
    },

    partsCount() {
      return this.selectedItem.parts ? this.selectedItem.parts.length : 0
    }
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  methods: {
    setItem() {
      if (this.item.id) {
        this.selectedItem = { ...this.item }
        delete this.selectedItem['__typename']
        this.selectedItem.parts.forEach(part => {
          delete part['__typename']
        })
        this.prepDates()
      } else {
        this.selectedItem = {}
        this.getEmptyDates()
      }
    },

    prepDates() {
      const { dateStartedStr, dateFinishedStr, deliveryDateStr, expirationDateStr } = this.selectedItem
      // simple
      this.dateStarted.simple = dateStartedStr
      this.dateFinished.simple = dateFinishedStr
      this.deliveryDate.simple = deliveryDateStr
      this.expirationDate.simple = expirationDateStr
      // holder text field
      this.dateStarted.holder = dateStartedStr ? this.momentDate(dateStartedStr, 'DD/MM/YYYY') : ''
      this.dateFinished.holder = dateFinishedStr ? this.momentDate(dateFinishedStr, 'DD/MM/YYYY') : ''
      this.deliveryDate.holder = deliveryDateStr ? this.momentDate(deliveryDateStr, 'DD/MM/YYYY') : ''
      this.expirationDate.holder = expirationDateStr ? this.momentDate(expirationDateStr, 'DD/MM/YYYY') : ''
      // picker
      this.dateStarted.picker = dateStartedStr ? this.formatDate(dateStartedStr) : ''
      this.dateFinished.picker = dateFinishedStr ? this.formatDate(dateFinishedStr) : ''
      this.deliveryDate.picker = deliveryDateStr ? this.formatDate(deliveryDateStr) : ''
      this.expirationDate.picker = expirationDateStr ? this.formatDate(expirationDateStr) : ''
      // startToDate
      this.startToDate = dateStartedStr ? this.dateStarted.picker : ''
    },

    getEmptyDates() {
      this.startToDate = ''
      this.dateStarted = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.dateFinished = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.deliveryDate = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.expirationDate = {
        simple: '',
        holder: '',
        picker: ''
      }
    },

    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'dateStarted') {
        this.startToDate = value.picker
      }
    },

    checkKeyCode(keyCode) {
      return ProjectService.checkKeyCode({ keyCode: keyCode })
    },

    async confirm() {
      this.loading = true

      // create a deep copy of selectedItem (we ensure with this approach that the frontend state is preserved)
      const payload = _.cloneDeep(this.selectedItem)

      if (payload.parts && payload.parts.length) {
        payload.parts.forEach(part => {
          delete part.isNew
        })
      }

      payload.dateStartedStr = this.dateStarted.simple ? this.formatDate(this.dateStarted.simple) : null
      payload.dateFinishedStr = this.dateFinished.simple ? this.formatDate(this.dateFinished.simple) : null
      payload.deliveryDateStr = this.deliveryDate.simple ? this.formatDate(this.deliveryDate.simple) : null
      payload.expirationDateStr = this.expirationDate.simple ? this.formatDate(this.expirationDate.simple) : null

      await ProjectService.save({
          projectRequestObject: payload
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    },

    addNewPart() {
      if (!this.selectedItem.parts) {
        this.$set(this.selectedItem, 'parts', [])
      }

      // add new part at the beginning
      this.selectedItem.parts.unshift({
        keyCode: '',
        description: '',
        isNew: true
      })
    },

    removePart(index) {
      if (this.selectedItem.parts[index].isNew) {
        this.selectedItem.parts.splice(index, 1)
      }
    },

    deleteConfirmation(part, index) {
      if (part.isNew) {
        this.removePart(index)
      } else {
        if (confirm(translations.areYouSure)) {
          this.deletePart(part, index)
        }
      }
    },

    async deletePart(part, index) {
      if (!part.isNew && part.id) {
        this.loading = true
        await ProjectService.deletePart({
            partId: part.id
          })
          .then(() => {
            this.$root.vtoast.show({ message: translations.deletedSuccess })
            this.selectedItem.parts.splice(index, 1)
          })
          .catch(() => {
            this.showErrorMsgDialog = true
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },

  watch: {
    show() {
      this.dialog = this.show
      if (!this.dialog) {
        this.tab = 'project'
      }
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>


<style>
.textarea-small, textarea {
  color: rgba(0, 0, 0, 0.6) !important;
  line-height: 20px !important;
  font-size: small !important;
}

.partTable td {
  padding-right: 10px;
}
</style>
