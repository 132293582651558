<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations[isUpdate ? 'updateSupplier' : 'newSupplier'] }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- keyCode -->
          <v-col class="pa-0 mt-4">
            <b class="ml-8">{{ translations.keyCode }} *</b>
            <smart-text-field v-model="selectedItem.keyCode" prepend-icon="mdi-roman-numeral-3"
                              counter="40"
                              :rules="[requiredRule]"
                              :already-exists-function="checkKeyCode"
                              @has-error="value => hasError.keyCode = value">
            </smart-text-field>
          </v-col>

          <!-- description -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.description }} *</b>
            <smart-text-field v-model="selectedItem.description" prepend-icon="mdi-text-long"
                              counter="40"
                              :rules="[requiredRule]"
                              :already-exists-function="checkDescription"
                              @has-error="value => hasError.description = value">
            </smart-text-field>
          </v-col>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { translations } from '@/utils/common'
import { Rules } from '@/utils/mixins/Rules'

import SupplierService from '@/service/supplier/SupplierService'

import SmartTextField from '@/components/SmartTextField'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'SupplierSaveEditDialog',

  components: {
    SmartTextField,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,
      hasError: {
        keyCode: false,
        description: false
      },

      selectedItem: {}
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    disableBtn() {
      return !(!this.hasError.keyCode && this.selectedItem.keyCode && this.selectedItem.keyCode.length <= 40
        && !this.hasError.description && this.selectedItem.description && this.selectedItem.description.length <= 40)
    }
  },

  mixins: [Rules],

  methods: {
    setItem() {
      if (this.item.id) {
        this.selectedItem = { ...this.item }
        delete this.selectedItem['__typename']
      } else {
        this.selectedItem = {}
      }
    },

    checkKeyCode(keyCode) {
      return SupplierService.checkSupplierKeyCode({ keyCode: keyCode })
    },

    checkDescription(description) {
      return SupplierService.checkSupplierDescription({ description: description })
    },

    async confirm() {
      this.loading = true

      await SupplierService.save({
          supplierDTO: this.selectedItem
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    }
  },

  watch: {
    show() {
      this.dialog = this.show
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>
