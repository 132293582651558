<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations[isUpdate ? 'editAccountProject' : 'newAccountProject'] }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- name -->
          <v-col class="pa-0">
            <!-- accounts -->
            <b>{{ translations.manageAccounts }}</b>

            <v-autocomplete v-if="!isUpdate" v-model="selectedItem.account.id" :items="accountList" item-text="name" item-value="id" :search-input.sync="accountToSearch" :placeholder="translations.accountFilterPlaceHolder" outlined hide-details dense clearable>
              <template v-slot:selection="data">
                {{ `${data.item.name}` }}
              </template>

              <template v-slot:item="data">
                {{ `${data.item.name}` }}
              </template>

              <template v-slot:append-item>
                <v-row v-if="hasNextAccounts && !$apollo.queries.backOfficeAccounts.loading" class="ma-0" justify="center">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" :size="20"></v-progress-circular>
                </v-row>
              </template>

              <template v-slot:no-data>
                <v-list-item>
                  {{ translations.noAccountFoundWThatName }}
                </v-list-item>
              </template>
            </v-autocomplete>

            <p v-else class="mb-0 mt-2">
              {{ `${item.account.name}` }}
            </p>
          </v-col>

          <!-- projects -->
          <v-col class="pa-0 mt-4">
            <b>{{ translations.projects }}</b>

            <MultiSelect :list-one-title="translations.available"
                         :list-one="filteredProjectList"
                         :list-two-title="translations.assigned"
                         :list-two="selectedItem.projects"
                         @updateList="handleUpdateList"
                         class="mt-0">
            </MultiSelect>
          </v-col>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn v-if="currentUserCanManageAccount"
                   @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import AccountProjectService from '@/service/accountProject/AccountProjectService'

import backOfficeAccounts from '@/service/account/BackOfficeAccountsQuery.gql'
import backOfficeProjectsQuery from '@/service/project/BackOfficeProjectsQuery.gql'

import MultiSelect from '@/components/MultiSelect'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'AccountSaveEditDialog',

  components: {
    MultiSelect,
    MessageDialog,
    WarningSvg
  },

  apollo: {
    backOfficeAccounts: {
      query: backOfficeAccounts,
      variables() {
        return {
          page: this.page,
          size: 20,
          sort: 'name',
          order: 'asc',
          name: this.accountToSearch,
          hasNextAccounts: false
        }
      },
      fetchPolicy: 'no-cache',
      result({ data }) {
        const { content, hasNext } = data.backOfficeAccounts

        this.accountList.push(...content)
        this.hasNextAccounts = hasNext
      }
    },

    backOfficeProjects: {
      query: backOfficeProjectsQuery,
      fetchPolicy: 'network-only',
      result({ data }) {
        this.projectList = data.backOfficeProjects.map(project => ({
          id: project.id,
          name: `${project.keyCode} - ${project.label}`
        }))
      }
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,

      selectedItem: {},

      accountToSearch: '',
      accountList: [],
      projectList: [],
      page: 1,
      hasNextAccounts: false
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    ...mapState({
      currentUserCanManageAccount: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage account')
    }),

    filteredProjectList() {
      if (!this.selectedItem.projects) {
        return this.projectList
      }
      const selectedProjectIds = new Set(this.selectedItem.projects.map(project => project.id))
      return this.projectList.filter(project => !selectedProjectIds.has(project.id))
    },

    disableBtn() {
      return !this.projectList || !this.selectedItem.account.id || !this.selectedItem.projects.length > 0
    }
  },

  mixins: [
    MomentMixin
  ],

  methods: {
    setItem() {
      if (this.item.account) {
        this.selectedItem = { ...this.item }
        this.removeTypename(this.selectedItem)
        // extract and combine into 'name' property
        if (this.selectedItem.projects) {
          this.selectedItem.projects = this.selectedItem.projects.map(project => ({
            id: project.id,
            name: `${project.keyCode} - ${project.label}`
          }))
        }
      } else {
        this.selectedItem = {
          account: { id: null, name: '' },
          projects: []
        }
      }
    },

    handleUpdateList({ updatedList }) {
      this.selectedItem.projects = updatedList
    },

    async confirm() {
      this.loading = true

      // prepare the object
      await AccountProjectService.save({
          accountProjectSaveOrUpdateDTO: {
            accountId: this.selectedItem.account.id,
            projectIds: this.selectedItem.projects.map(({ id }) => id)
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    },

    removeTypename(obj) {
      Object.keys(obj).forEach(key => {
        if (key === '__typename') {
          delete obj[key]
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.removeTypename(obj[key])
        }
      })
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
      }
    }
  },

  watch: {
    show() {
      this.dialog = this.show
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>
