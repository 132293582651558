import apolloUtil from '@/service/ApolloUtil'
import axios from '@/utils/api/axios.js'

import propertiesQuery from '@/service/property/PropertiesQuery.gql'
import savePropertyMutation from '@/service/property/SavePropertyMutation.gql'
import deletePropertyMutation from '@/service/property/DeletePropertyMutation.gql'

class PropertyService {

  search(params) {
    return apolloUtil.query(propertiesQuery, params)
      .then((response) => response?.data?.properties)
  }

  save(params) {
    return apolloUtil.mutate(savePropertyMutation, params)
      .then((response) => response?.data?.saveProperty)
  }

  delete(params) {
    return apolloUtil.mutate(deletePropertyMutation, params)
  }

  async getEnumValues(key) {
    try {
      switch (key) {
        case 'propertyType':
          return await axios.get('property-values/property-type')
        case 'employeeDriverMethod':
          return await axios.get('property-values/employee-driver-method')
        case 'roundingRule':
          return await axios.get('property-values/rounding-rule')
        case 'teamType':
          return await axios.get('property-values/team-type')
      }
    } catch (error) {
      return []
    }
  }
}

export default new PropertyService()
