import apolloUtil from '@/service/ApolloUtil'

import accountsQuery from '@/service/account/AccountsQuery.gql'
import retrieveEmployeeInfoQuery from '@/service/account/RetrieveEmployeeInfoQuery.gql'
import saveAccountMutation from '@/service/account/SaveAccountMutation.gql'
import deleteAccountMutation from '@/service/account/DeleteAccountMutation.gql'
import checkActiveEmployeeQuery from '@/service/account/CheckActiveEmployeeQuery.gql'
import checkActiveEmployeeWithEmailQuery from '@/service/account/CheckActiveEmployeeWithEmailQuery.gql'
import backOfficeAccounts from '@/service/account/BackOfficeAccountsQuery.gql'

class AccountService {

  search(params) {
    return apolloUtil.query(accountsQuery, params)
      .then((response) => response?.data?.accountAndEmployees)
  }

  retrieveEmployeeInfo(params) {
    return apolloUtil.query(retrieveEmployeeInfoQuery, params)
      .then(response => response?.data?.retrieveEmployeeInfo)
  }

  save(params) {
    return apolloUtil.mutate(saveAccountMutation, params, false)
  }

  delete(params) {
    return apolloUtil.mutate(deleteAccountMutation, params)
  }

  checkKeyCodeEmployee(keyCode) {
    return apolloUtil.query(checkActiveEmployeeQuery, keyCode)
      .then((response) => response?.data?.isEmployeeActive)
  }

  checkAccountEmail(email) {
    return apolloUtil.query(checkActiveEmployeeWithEmailQuery, email)
      .then((response) => response?.data?.isAccountActiveWithEmail)
  }

  backOfficeAccounts() {
    return apolloUtil.query(backOfficeAccounts)
      .then((response) => response?.data?.backOfficeAccounts)
  }

}

export default new AccountService()
