import apolloUtil from '@/service/ApolloUtil'

import accountProjectsQuery from '@/service/accountProject/AccountProjectsQuery.gql'
import saveOrUpdateAccountProjectMutation from '@/service/accountProject/SaveAccountProjectMutation.gql'
import deleteAccountProjectMutation from '@/service/accountProject/DeleteAccountProjectMutation.gql'

class AccountProjectService {

  search(params) {
    return apolloUtil.query(accountProjectsQuery, params)
      .then((response) => response?.data?.accountProjects)
  }

  save(params) {
    return apolloUtil.mutate(saveOrUpdateAccountProjectMutation, params)
      .then((response) => response?.data?.saveOrUpdateAccountProject)
  }

  delete(params) {
    return apolloUtil.mutate(deleteAccountProjectMutation, params)
  }

}

export default new AccountProjectService()
