<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations[isUpdate ? 'updateVehicle' : 'newVehicle'] }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- keyCode -->
          <v-col class="pa-0 mt-4">
            <b class="ml-8">{{ translations.keyCode }} *</b>
            <smart-text-field v-model="selectedItem.keyCode" prepend-icon="mdi-roman-numeral-3"
                              :readonly="isReadOnly" counter="20"
                              :rules="[requiredRule]"
                              :already-exists-function="checkKeyCode"
                              @has-error="value => hasError.keyCode = value">
            </smart-text-field>
          </v-col>

          <!-- code -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.code }} *</b>
            <smart-text-field v-model="selectedItem.code" prepend-icon="mdi-roman-numeral-3"
                              :readonly="isReadOnly" counter="20"
                              :rules="[requiredRule]"
                              :already-exists-function="checkCode"
                              @has-error="value => hasError.code = value">
            </smart-text-field>
          </v-col>

          <!-- label -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.label }} *</b>
            <v-text-field v-model="selectedItem.label" prepend-icon="mdi-text"
                          :readonly="isReadOnly" :rules="[requiredRule]"
                          class="mt-1" outlined dense>
            </v-text-field>
          </v-col>

          <!-- description -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.description }} *</b>
            <v-textarea v-model="selectedItem.description" prepend-icon="mdi-text-long"
                        :readonly="isReadOnly" :rules="[requiredRule]"
                        class="mt-1 textarea-small" outlined dense>
            </v-textarea>
          </v-col>

          <v-divider class="mt-5 mb-10"></v-divider>

          <!-- startDate -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.startDate }} *</b>
            <date-picker :dateProp="startDate"
                         @selected-date="value => setDate(value, 'startDate')"
                         :disable-picker="isReadOnly"
                         :classToAdd="['date-picker-component']">
            </date-picker>
          </v-col>

          <!-- endDate -->
          <v-col class="pa-0 mt-8">
            <b class="ml-8">{{ translations.endDate }}</b>
            <date-picker :dateProp="endDate"
                         :startToDate="startToDate"
                         :minDateProp="startDate.simple"
                         @selected-date="value => setDate(value, 'endDate')"
                         :disable-picker="isReadOnly"
                         :clearable="!isReadOnly"
                         :classToAdd="['date-picker-component']">
            </date-picker>
          </v-col>

          <v-divider class="mt-12 mb-10"></v-divider>

          <!-- license -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.license }}</b>
            <v-text-field v-model="selectedItem.license" prepend-icon="mdi-card-bulleted-outline"
                          :readonly="isReadOnly" counter="50"
                          :clearable="!isReadOnly"
                          class="mt-1" outlined dense>
            </v-text-field>
          </v-col>

          <!-- frame -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.frame }}</b>
            <v-text-field v-model="selectedItem.frame" prepend-icon="mdi-texture-box"
                          :readonly="isReadOnly" counter="50"
                          :clearable="!isReadOnly"
                          class="mt-1" outlined dense>
            </v-text-field>
          </v-col>

          <!-- model -->
          <v-col class="pa-0 mt-1">
            <b class="ml-8">{{ translations.model }}</b>
            <v-text-field v-model="selectedItem.model" prepend-icon="mdi-tow-truck"
                          :readonly="isReadOnly" counter="50"
                          :clearable="!isReadOnly"
                          class="mt-1" outlined dense>
            </v-text-field>
          </v-col>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn v-if="currentUserCanManageCRUD"
                   @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'
import { Rules } from '@/utils/mixins/Rules'

import VehicleService from '@/service/vehicle/VehicleService'

import SmartTextField from '@/components/SmartTextField'
import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'VehicleSaveEditDialog',

  components: {
    SmartTextField,
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,
      hasError: {
        keyCode: false,
        code: false
      },

      selectedItem: {},
      startToDate: '',
      startDate: null,
      endDate: null
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    ...mapState({
      currentUserCanManageCRUD: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage CRUD')
    }),

    disableBtn() {
      return !(
        (!this.hasError.keyCode && this.validRequired(this.selectedItem.keyCode, 20))
        && (!this.hasError.code && this.validRequired(this.selectedItem.code, 20))
        && (this.validRequired(this.selectedItem.label))
        && (this.validRequired(this.selectedItem.description))

        && (!!this.startDate.simple)

        && (!this.selectedItem.license || this.selectedItem.license.length <= 50)
        && (!this.selectedItem.frame || this.selectedItem.frame.length <= 50)
        && (!this.selectedItem.model || this.selectedItem.model.length <= 50)
      )
    },

    isReadOnly() {
      return !this.currentUserCanManageCRUD
    }
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  methods: {
    setItem() {
      if (this.item.id) {
        this.selectedItem = { ...this.item }
        delete this.selectedItem['__typename']
        this.prepDates()
      } else {
        this.selectedItem = {}
        this.getEmptyDates()
      }
    },

    prepDates() {
      const { startDateStr, endDateStr } = this.selectedItem
      // simple
      this.startDate.simple = startDateStr
      this.endDate.simple = endDateStr
      // holder text field
      this.startDate.holder = startDateStr ? this.momentDate(startDateStr, 'DD/MM/YYYY') : ''
      this.endDate.holder = endDateStr ? this.momentDate(endDateStr, 'DD/MM/YYYY') : ''
      // picker
      this.startDate.picker = startDateStr ? this.formatDate(startDateStr) : ''
      this.endDate.picker = endDateStr ? this.formatDate(endDateStr) : ''
      // startToDate
      this.startToDate = startDateStr ? this.startDate.picker : ''
    },

    getEmptyDates() {
      this.startToDate = ''
      this.startDate = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.endDate = {
        simple: '',
        holder: '',
        picker: ''
      }
    },

    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'startDate') {
        this.startToDate = value.picker
      }
    },

    checkKeyCode(keyCode) {
      return VehicleService.checkKeyCode({ keyCode: keyCode })
    },

    checkCode(code) {
      return VehicleService.checkCode({ code: code })
    },

    async confirm() {
      this.loading = true

      // prepare the object
      this.selectedItem.startDateStr = this.startDate.simple ? this.formatDate(this.startDate.simple) : null
      this.selectedItem.endDateStr = this.endDate.simple ? this.formatDate(this.endDate.simple) : null

      await VehicleService.save({
          vehicleDTO: this.selectedItem
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    }
  },

  watch: {
    show() {
      this.dialog = this.show
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>


<style>
.textarea-small, textarea {
  color: rgba(0, 0, 0, 0.6) !important;
  line-height: 20px !important;
  font-size: small !important;
}
</style>
